html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Varela Round", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page-content {
  max-width: 100vw;
}

#contact {
  padding: 0 30px;
}

.center {
  display:flex;
  flex-direction: column;
  align-items: center;
}

p.center{
  text-align: center;
}

.email {
  font-weight: bold;
  font-size: 18px;
}

.slim {
  max-width: 768px;
}

/* header styles */

#title {
  display: flex;
  flex-direction: row;
  user-select: none;
}

#title h1 {
  font-size: 72px;
}

#title h3 {
  padding: 14px 5px 0 0;
  font-size: 24px;
}

@media (max-width: 450px) {
  #title h1 {
    font-size: 60px;
  }
  
  #title h3 {
    padding: 14px 5px 0 0;
    font-size: 20px;
  }
}

/* nav styles */

#nav {
  width: 100%;
}

#nav-bar {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 0 20px 0;
  font-size: 16px;
}

#nav-bar a:link {
  text-decoration: none;
}

.nav-item {
  padding: 5px 25px;
  color: #888;
  border: 1px solid transparent;
  transition: border 0.5s, color 0.5s;
  user-select: none;
  white-space: nowrap;
}

.nav-item.selected{
  color: black;
  font-weight:bold
}

.nav-item:hover {
  border: 1px solid lightgray;
  color: black;
}

.nav-bar-subsection {
  display: flex;
  justify-content: center;
}

.stackable {
  flex-direction: row;
}

@media (max-width: 700px) {
  .stackable {
    flex-direction: column;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.show {
  display: block;
}

.dropdown-chevron {
  height: .75em;
  width: .75em;
  fill: #888;
  margin-left: 5px;
}

/* image gallery styles */

#gallery {
  padding: 0 20px;
}

#gallery .gallery-row {
  display: flex;
  flex-direction: row;
}

#gallery .gallery-image-background {
  margin: 10px;
  background-color: black;
}

#gallery .gallery-image {
  object-fit: cover;
}

#gallery .gallery-image:hover {
  opacity: 80%;
}

/* single image styles */

.singleimage {
  margin: 10px 0;
  max-width: 95vw;
}

.singleimage-nav a,.link-disabled{
  padding: 5px;
  font-size: 20px;
}

.singleimage-nav a {
  color: black;
  text-decoration: none;
}

.singleimage-nav .link-disabled {
  color: #AAA;
}

.singleimage-nav .singleimage-nav-back {
  font-weight: bold;
}

.no-image {
  padding: 200px 0;
}